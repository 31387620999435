import { Space } from 'antd';
import { format } from 'date-fns';
import i18n from 'i18next';

import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { BinUserUploadButton } from './BinUserUploadButton';

export const columnsDesktop = (getUserUploads: () => Promise<void>) => [
  {
    dataIndex: 'image_url',
    key: 'image',
    title: i18n.t('column_titles:image'),
    width: 160,
    render: (text: string) => (
      <FrontImageStyled width={'8vw'} height={'8vw'} src={text} />
    ),
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: i18n.t('column_titles:description'),
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    title: i18n.t('column_titles:uploaded_at'),
    columnType: 'date',
    render: (updated_at: string) => {
      const date = new Date(updated_at);
      return <span>{format(date, "yyyy-MM-dd HH:mm:ss 'UTC'")}</span>;
    },
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: i18n.t('column_titles:action'),
    render: (id: string) => (
      <Space size="middle">
        <>
          <BinUserUploadButton
            userUploadId={id}
            getUserUploads={getUserUploads}
          />
        </>
      </Space>
    ),
  },
];
