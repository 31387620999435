import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { refreshProjectList } from '../../redux/list';
import { api } from '../api/api';

/**
 * Copies all the fabrics in a project to an existing project.
 */
export async function copyProjectToExistingProject(
  srcProjId: string,
  dstProjId: string
): Promise<boolean> {
  const data = { src_project_id: srcProjId, dst_project_id: dstProjId };

  try {
    await api({
      method: 'POST',
      url: 'v1/project/copy_from_project',
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    message.success(
      i18n.t('long_messages:message_new_project_add_fabric_success')
    );
    refreshProjectList();
    return true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
    return false;
  }
}
