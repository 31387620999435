import { UploadOutlined } from '@ant-design/icons';
import { Button, Row, Space, Switch, Upload, message } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import { CreateBulkUploadRow } from '../../../elements/modals/StyledModal';
import { createBulkUpload } from '../../../utils/backendRequests/bulkUpload/createBulkUpload';
import { Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { LibraryDropdown } from './LibraryDropdown';

interface ModalProps {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  fetchData: () => void;
}

export function CreateBulkUploadModal({
  modal,
  setModal,
  fetchData,
}: ModalProps) {
  const [libraryId, setLibraryId] = useState<string>('');
  const [populateWeightFields, setPopulateWeightFields] =
    useState<boolean>(false);
  const [populatePriceFields, setPopulatePriceFields] =
    useState<boolean>(false);
  const [populateMOQFields, setPopulateMOQFields] = useState<boolean>(false);
  const [populateMCQFields, setPopulateMCQFields] = useState<boolean>(false);
  const [populateStockFields, setPopulateStockFields] =
    useState<boolean>(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (!modal.visible) {
      setLibraryId(''); // Resetting to an empty string
      setPopulateWeightFields(false);
      setPopulatePriceFields(false);
      setPopulateMOQFields(false);
      setPopulateMCQFields(false);
      setPopulateStockFields(false);
      setFileList([]);
    }
  }, [modal.visible]);

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleFileRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const handleOk = async () => {
    if (fileList.length > 0 && libraryId) {
      const formData = new FormData();
      const file = fileList[0];
      formData.append('xlsx_file', file.originFileObj as Blob);
      const details = {
        library_id: libraryId,
        populate_weight_fields: populateWeightFields,
        populate_price_fields: populatePriceFields,
        populate_moq_fields: populateMOQFields,
        populate_mcq_fields: populateMCQFields,
        populate_stock_fields: populateStockFields,
      };
      formData.append('details', JSON.stringify(details));

      const response = await createBulkUpload(formData);
      if (response) {
        setModal({ visible: false });
        // Reset the form
        setPopulateWeightFields(false);
        setPopulatePriceFields(false);
        setPopulateMOQFields(false);
        setPopulateMCQFields(false);
        setPopulateStockFields(false);
        setLibraryId(''); // Ensure the dropdown resets
        setFileList([]);

        fetchData();
      } else {
        // Handle the error case
        message.error(i18n.t('error_messages:error_upload_failed'));
      }
    } else {
      // Handle the case where no file is selected or library is not set
      message.error(i18n.t('error_messages:error_select_file_library'));
    }
  };

  const props = {
    maxCount: 1,
    accept: '.xlsx',
    beforeUpload: () => false, // Allow files of any size
    onChange: handleFileChange,
    onRemove: handleFileRemove,
    fileList,
  };

  return (
    <Modal
      title={i18n.t('headers:import_new_fabrics')}
      open={modal.visible}
      onOk={handleOk}
      onCancel={() => setModal({ visible: false })}
    >
      <Row>
        {i18n.t('headers:library')}:
        <LibraryDropdown value={libraryId} setLibraryId={setLibraryId} />
      </Row>
      <CreateBulkUploadRow>{i18n.t('headers:input_file')}:</CreateBulkUploadRow>
      <Row wrap={false}>
        <Upload {...props}>
          <Button disabled={fileList.length !== 0} icon={<UploadOutlined />}>
            {i18n.t('buttons:select_files')}
          </Button>
        </Upload>
      </Row>
      <CreateBulkUploadRow>
        <Space>
          {i18n.t('headers:populate_weight_fields')}
          <HelpIcon
            title={i18n.t(
              'long_messages:message_populate_weight_fields_explain'
            )}
            pos="right"
            fontSize="10px"
            margin="0 -3px 0 -3px"
          />
          :
          <Switch
            checked={populateWeightFields}
            onChange={setPopulateWeightFields}
          />
        </Space>
      </CreateBulkUploadRow>
      <CreateBulkUploadRow>
        <Space>
          {i18n.t('headers:populate_price_fields')}
          <HelpIcon
            title={i18n.t(
              'long_messages:message_populate_price_fields_explain'
            )}
            pos="right"
            fontSize="10px"
            margin="0 -3px 0 -3px"
          />
          :
          <Switch
            checked={populatePriceFields}
            onChange={setPopulatePriceFields}
          />
        </Space>
      </CreateBulkUploadRow>
      <CreateBulkUploadRow>
        <Space>
          {i18n.t('headers:populate_moq_fields')}
          <HelpIcon
            title={i18n.t('long_messages:message_populate_moq_fields_explain')}
            pos="right"
            fontSize="10px"
            margin="0 -3px 0 -3px"
          />
          :
          <Switch checked={populateMOQFields} onChange={setPopulateMOQFields} />
        </Space>
      </CreateBulkUploadRow>
      <CreateBulkUploadRow>
        <Space>
          {i18n.t('headers:populate_mcq_fields')}
          <HelpIcon
            title={i18n.t('long_messages:message_populate_mcq_fields_explain')}
            pos="right"
            fontSize="10px"
            margin="0 -3px 0 -3px"
          />
          :
          <Switch checked={populateMCQFields} onChange={setPopulateMCQFields} />
        </Space>
      </CreateBulkUploadRow>
      <CreateBulkUploadRow>
        <Space>
          {i18n.t('headers:populate_stock_fields')}
          <HelpIcon
            title={i18n.t(
              'long_messages:message_populate_stock_fields_explain'
            )}
            pos="right"
            fontSize="10px"
            margin="0 -3px 0 -3px"
          />
          :
          <Switch
            checked={populateStockFields}
            onChange={setPopulateStockFields}
          />
        </Space>
      </CreateBulkUploadRow>
    </Modal>
  );
}
