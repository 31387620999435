import { Select } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getSharedUsers } from '../../../utils/backendRequests/project/getSharedUsers';
import { shareProject } from '../../../utils/backendRequests/project/shareProject';
import { Modal } from '../../Modal';
import { ShareProjectProps, sharedUser } from '../TableTypes';
import { SharedUsers } from './SharedUsers';

export function ShareProject({ projectId, approvedUsers }: ShareProjectProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<sharedUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<sharedUser[]>([]);
  const [unsharedUsers, setUnsharedUsers] = useState<sharedUser[]>([]);

  useEffect(() => {
    async function _getSharedUsers() {
      const sharedUserData = await getSharedUsers(projectId);
      setSharedUsers(sharedUserData);
    }
    if (isModalOpen) _getSharedUsers(); // Only make API call when the modal is opened
  }, [projectId, isModalOpen]);

  useEffect(() => {
    // Converts list of users (Object) to user ids (int) for easy filtering
    const sharedUserIds = sharedUsers.map((user: sharedUser) => user.key);
    // This updates the list of users who have not been shared with previously
    // Users will be unable to select a user they have already shared the project with
    setUnsharedUsers(
      approvedUsers.filter((user) => !sharedUserIds.includes(user.key))
    );
  }, [approvedUsers, sharedUsers]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value: sharedUser[], option: sharedUser[]) => {
    // Updates the list of users that the user has selected
    setSelectedUsers(option);
  };

  const handleOk = () => {
    // Merge selected and previously shared with users
    const updatedSharedUsers = sharedUsers.concat(selectedUsers);
    // Convert User objects to their Ids for backend API call
    const selectedUserIds = updatedSharedUsers.map(
      (user: sharedUser) => user.key
    );
    shareProject(projectId, selectedUserIds);
    // Updates list of shared users after API call is completed
    setSharedUsers(updatedSharedUsers);
    // Clears the list of selected users to prevent resubmission of same users
    setSelectedUsers([]);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Link onClick={showModal} to={''}>
        {i18n.t('buttons:share')}
      </Link>
      <Modal
        title={i18n.t('headers:share_with_users')}
        open={isModalOpen}
        okText={i18n.t('buttons:share')}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={i18n.t('headers:add_more_users')}
          // @ts-expect-error because Antd restrictions
          onChange={handleChange}
          options={unsharedUsers}
          value={selectedUsers}
        />
        <SharedUsers
          sharedUsers={sharedUsers}
          projectId={projectId}
          setSharedUsers={setSharedUsers}
        />
      </Modal>
    </>
  );
}
