import { message } from 'antd';
import i18n from 'i18next';
import { refreshProjectList } from '../../redux/list';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Unshares a project, specified by name.
 */
export async function unshareProject(projectId: string): Promise<MsgProp> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/project/${projectId}/unshare`,
    }).then((res: MsgProp) => {
      message.success(i18n.t('long_messages:message_project_unshared_success'));

      // Refresh redux store after project successfully binned
      refreshProjectList();
      return res;
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
