import { Button, Col, Row } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TabTitle } from '../components/TabTitle';
import { CreateFabricLibraryModal } from '../components/modal/CreateFabricLibraryModal';
import { HeaderTitle } from '../components/title/HeaderTitle';
import { SubTitleH6 } from '../elements/typography/SubTitleH6';
import { TextB2 } from '../elements/typography/TextB2';
import { MainLayout } from '../layouts/Main';
import { routesNamedDict } from '../routes';
import { SectionWrapper } from '../styles/wrappers/SectionWrapper';
import { RootAuth, RootLibrary, RootState } from '../utils/types/Types';
import { TitleProp } from './PagesTypes';

export function HomePage({ title }: TitleProp) {
  const navigate = useNavigate();
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const libraries = useSelector(
    (state: RootLibrary) => state.fabricLibrary.libraryList
  );
  const [modal, setModal] = useState({ visible: false });
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const handleLibrary = (libraryId: string) => {
    // Please refer to routes/fabrics.js for the problem replace() is addressing
    navigate(
      routesNamedDict.FABRICS_LIBRARY_ID.replace(/:libraryId/g, '') + libraryId
    );
  };

  const handleNew = () => {
    setModal({ visible: true });
  };

  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:home_page')} />
      <TabTitle title={title} />
      <SectionWrapper>
        {role === 'supplier' && (
          <>
            <SubTitleH6>{i18n.t('headers:view_libraries')}</SubTitleH6>
            <CreateFabricLibraryModal setModal={setModal} modal={modal} />
            {account !== 'viewer' && (
              <Button onClick={handleNew} style={{ marginTop: '15px' }}>
                {i18n.t('headers:new_library')}
              </Button>
            )}
            <TextB2>
              {i18n.t('long_messages:message_home_libraries_owned')}
            </TextB2>
            {libraries.map((library, i) => (
              <Row key={i}>
                <Col
                  span={isMobileFlag ? 8 : 4}
                  style={{ marginBottom: '10px' }}
                >
                  <Button onClick={() => handleLibrary(library.id)}>
                    {library.name}
                  </Button>
                </Col>
              </Row>
            ))}
          </>
        )}
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>{i18n.t('headers:view_user_uploads')}</SubTitleH6>
        <TextB2>{i18n.t('long_messages:message_home_user_uploads')}</TextB2>
        <Button onClick={() => navigate(routesNamedDict.USER_UPLOADS)}>
          {i18n.t('headers:user_uploads')}
        </Button>
      </SectionWrapper>
    </MainLayout>
  );
}
