import { Space } from 'antd';
import { format } from 'date-fns';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import { routesNamedDict } from '../../../routes';
import { LibraryRecord } from '../TableTypes';
import { BinFabricLibraryButton } from './BinFabricLibraryButton';
import { CopyLibraryToCollectionLink } from './CopyLibraryToCollectionLink';
import { EditLib } from './EditLib';

export const columnsDesktop = (account: string | null) => [
  {
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('common:name'),
    columnType: 'string',
    render: (name: string) => (
      <span>
        <span style={{ marginLeft: '15px' }}>{name}</span>
      </span>
    ),
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: i18n.t('column_titles:description'),
  },
  {
    dataIndex: 'owner',
    key: 'owner',
    title: i18n.t('column_titles:owner'),
    columnType: 'string',
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
    render: (updated_at: string) => {
      const date = new Date(updated_at);
      return <span> {format(date, "yyyy-MM-dd HH:mm:ss 'UTC'")} </span>;
    },
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: i18n.t('column_titles:action'),
    render: (_: unknown, record: LibraryRecord) => {
      return (
        <Space size="middle">
          <Link
            to={
              routesNamedDict.FABRICS_LIBRARY_ID.replace(/:libraryId/g, '') +
              record.id
            }
          >
            {i18n.t('column_titles:view')}
          </Link>
          <EditLib record={record} />
          {account !== 'viewer' && (
            <CopyLibraryToCollectionLink libraryId={record.id} />
          )}
          {/* <ExportLibraryButton data={record} /> Disabled as approach needs to be changed with regards to pagination*/}
          {record.is_owner && <BinFabricLibraryButton fabricId={record.id} />}
        </Space>
      );
    },
  },
];
