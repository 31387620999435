import { Select } from 'antd';
import i18n from 'i18next';
import { useSelector } from 'react-redux';

import { libraryProp } from '../../../utils/backendRequests/list/libraries';
import { RootLibrary, Setter } from '../../../utils/types/Types';

interface LibraryDropdownProps {
  value: string | undefined; // Accept undefined as well
  setLibraryId: Setter<string>;
}

export const LibraryDropdown = ({
  value,
  setLibraryId,
}: LibraryDropdownProps) => {
  const libraries = useSelector(
    (state: RootLibrary) => state.fabricLibrary.libraryList
  );

  const libraryOptions = libraries.map((library: libraryProp) => (
    <Select.Option key={library.id} value={library.id}>
      {library.name}
    </Select.Option>
  ));

  const handleSelect = (value: string) => {
    setLibraryId(value);
  };

  return (
    <Select
      style={{ width: '100%' }}
      placeholder={i18n.t('long_messages:message_library_dropdown')}
      value={value || undefined} // Ensure undefined when no value
      onChange={handleSelect}
      allowClear // Add this to allow clearing the selection
    >
      {libraryOptions}
    </Select>
  );
};
