import { Collapse, Drawer } from 'antd';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FilterButtonsContainer } from '../../../elements/StyledUI';
import {
  ApplyFilterButton,
  ResetFilterButton,
} from '../../../elements/buttons/StyledButtons';
import { dayjs } from '../../../plugins/dayjs';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { filterNullFields } from '../../../utils/parsers/parseNullColumns';
import { RootAuth } from '../../../utils/types/Types';
import { formFieldsProp } from '../../form/FormTypes';
import {
  BackendDateFilter,
  BackendGenericFilter,
  BackendNumberFilter,
  FabricFilterDrawerProps,
  FilterValues,
  InitialChoicesOrRange,
  ObjectFilterItem,
  TransformedFilterProps,
} from '../FilterTypes';
import { CertFilter } from '../filterTemplate/CertFilter';
import { CheckboxFilter } from '../filterTemplate/CheckboxFilter';
import { DateRangeFilter } from '../filterTemplate/DateRangeFilter';
import { NumberRangeFilter } from '../filterTemplate/NumberRangeFilter';
import {
  createInitialChoicesOrRange,
  createInitialFilterValues,
} from './initialValues';

export function FabricFilterDrawer({
  initialData,
  filterOpen,
  closeFilter,
  setFilterableColumns,
  filterableFields,
}: FabricFilterDrawerProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  const baseFilterFabricProperties = useMemo(
    () => returnOrderedFields(Object.keys(filterableFields).map((key) => key)),
    [filterableFields]
  );

  //We can add or remove properties here based on the requirements of that fabric table
  const [filterFabricProperties, setFilterFabricProperties] = useState(
    baseFilterFabricProperties
  );

  useEffect(() => {
    setFilterFabricProperties(baseFilterFabricProperties);
  }, [filterableFields]);

  //Adds filter properties to dynamic fabric properties
  const [initialFilterValues, setInitialFilterValues] = useState(() =>
    createInitialFilterValues(filterFabricProperties)
  );

  //Check if similarity column exists in the table and update filterFabricProperties
  useEffect(() => {
    const hasSimilarityScore = initialData.some(
      (record) => record.similarity_score !== undefined
    );
    // Check if similarity column exists, and if the table
    // contains a required similarity score then add and update the fabric properties
    // state, otherwise filter it out from the fabric properties
    setFilterFabricProperties((prevProperties) => {
      const similarityFieldExists = prevProperties.some(
        (field) => field.name === 'similarity_score'
      );
      if (hasSimilarityScore && !similarityFieldExists) {
        return [
          ...prevProperties,
          ...returnOrderedFields(['similarity_score']),
        ];
      } else if (!hasSimilarityScore && similarityFieldExists) {
        return prevProperties.filter(
          (field) => field.name !== 'similarity_score'
        );
      } else {
        return prevProperties;
      }
    });
  }, [initialData]);

  // Update initialFilterValues when filterFabricProperties change
  useEffect(() => {
    setInitialFilterValues(createInitialFilterValues(filterFabricProperties));
  }, [filterFabricProperties]);

  const filterNullFieldsData = useMemo(
    () => filterNullFields(initialData),
    [initialData]
  );

  const [filteredColumns, setFilteredColumns] = useState<formFieldsProp[] | []>(
    []
  );

  //Allow when all data is null for each single filter type
  useEffect(() => {
    const notNullSelectableColumns = filterFabricProperties.filter(
      (column) => !filterNullFieldsData.includes(column.name)
    );
    setFilteredColumns(notNullSelectableColumns);
  }, [filterFabricProperties, filterNullFieldsData]);

  const initialChoicesOrRange = useMemo(
    () => createInitialChoicesOrRange(initialFilterValues, filterableFields),
    [initialFilterValues]
  );

  const [currentFilterValues, setCurrentFilterValues] =
    useState<FilterValues>(initialFilterValues);

  // Keep filter value range or choices updated if filter properties change
  useEffect(() => {
    setCurrentFilterValues(initialFilterValues);
  }, [initialFilterValues]);

  // Determines the values to filter by for each field
  const [ChoicesOrRange, setChoicesOrRange] = useState<InitialChoicesOrRange>(
    initialChoicesOrRange
  ); // Determines the possible filter values for each field

  // Backend data is fetched and stored filterableFields
  useEffect(() => {
    if (filterableFields && initialFilterValues) {
      // Set possible filter values
      setChoicesOrRange(initialChoicesOrRange);

      // Set current filter values
      setCurrentFilterValues((previousState) => {
        const newState = { ...previousState };
        Object.keys(initialChoicesOrRange).forEach((key) => {
          newState[key] = {
            ...previousState[key],
            values: initialChoicesOrRange[key],
            includeNull: filterableFields[key].has_null,
            // Setting required so option to filter by null is not shown since it has no null values
            required: !filterableFields[key].has_null,
          };
        });
        return newState;
      });
    }
  }, [filterableFields, initialFilterValues]);

  // Type guard for dayjs
  const isDayjs = (value: unknown): value is dayjs.Dayjs => {
    return dayjs.isDayjs(value);
  };

  // Transform FilterValues to the desired format
  const applyFilters = () => {
    const transformed: TransformedFilterProps = {};
    for (const key in currentFilterValues) {
      if (!(key in filterableFields)) {
        continue; // Skip if key is not in filterableFields
      }
      // Get the filter values for the current key
      const filter = currentFilterValues[key];
      // Get the original filter values from the backend for the current key
      const originalFilter = filterableFields[key];
      // Check if filter.values is an array and has length greater than 0
      if (Array.isArray(filter.values) && filter.values.length > 0) {
        switch (filter.type) {
          case 'number':
            // Only add to transformed if the values are different from the original filter
            if (
              filter.values[0] !==
                (originalFilter as BackendNumberFilter).min ||
              filter.values[1] !==
                (originalFilter as BackendNumberFilter).max ||
              filter.includeNull !== originalFilter.has_null
            ) {
              transformed[key] = {
                min: filter.values[0],
                max: filter.values[1],
                include_null: filter.includeNull,
              };
            }
            break;
          case 'date':
            // Only add to transformed if the values are different from the original filter
            if (
              filter.values[0] !== (originalFilter as BackendDateFilter).min ||
              filter.values[1] !== (originalFilter as BackendDateFilter).max ||
              filter.includeNull !== originalFilter.has_null
            ) {
              transformed[key] = {
                min: isDayjs(filter.values[0])
                  ? filter.values[0].format('YYYY-MM-DD')
                  : filter.values[0],
                max: isDayjs(filter.values[1])
                  ? filter.values[1].format('YYYY-MM-DD')
                  : filter.values[1],
                include_null: filter.includeNull,
              };
            }
            break;
          default:
            // Handle other cases, treating filter.values as strings or arrays of strings
            // Only add to transformed if the values are different from the original filter
            if (
              filter.values.length !==
                (originalFilter as BackendGenericFilter).values.length ||
              filter.includeNull !== originalFilter.has_null
            ) {
              transformed[key] = {
                value: filter.values,
                include_null: filter.includeNull,
              };
            }
            break;
        }
      }
    }
    setFilterableColumns(transformed);
    closeFilter(); // Close the drawer
  };

  /**
   * Resets the filters applied to the table by:
   * - Resetting the data shown on the table using the setData function
   * - Resetting the current filter values by using the setCurrentFilterValues function
   *   with values from the ChoicesOrRange object applied which represents the default
   *   filter values.
   * - It also resets the includeNull checkbox to its original includeNull state
   */
  const resetFilters = () => {
    // Reset current filter values
    setCurrentFilterValues((previousState) => {
      const newState = { ...previousState };
      Object.keys(ChoicesOrRange).forEach((key) => {
        newState[key] = {
          ...previousState[key],
          values: ChoicesOrRange[key],
          includeNull: filterableFields[key].has_null,
        };
      });
      return newState;
    });
  };

  const filterItems = () => {
    const items: { label: string; key: number; children: React.ReactNode }[] =
      [];
    filteredColumns.forEach((element, index) => {
      const obj: ObjectFilterItem = {
        label: parseFabricTitles(element.name, measurementUnit),
        key: index,
        children: null,
      };
      // Handle specifically certs
      if (element.name === 'certifications') {
        obj.children = (
          <CertFilter
            choices={ChoicesOrRange[element.name]}
            field={element.name}
            isTranslatable={element.isTranslatable || false}
            currentFilterValues={currentFilterValues}
            setCurrentFilterValues={setCurrentFilterValues}
          />
        );
      } else if (
        element.fieldType === 'string' ||
        element.fieldType === 'singleDropdown' ||
        element.fieldType === 'multiDropdown'
      ) {
        obj.children = (
          <CheckboxFilter
            choices={ChoicesOrRange[element.name]}
            field={element.name}
            isTranslatable={element.isTranslatable || false}
            currentFilterValues={currentFilterValues}
            setCurrentFilterValues={setCurrentFilterValues}
          />
        );
      } else if (
        element.fieldType === 'number' ||
        element.fieldType === 'numberInteger'
      ) {
        obj.children = (
          <NumberRangeFilter
            range={ChoicesOrRange[element.name] as [number, number]}
            field={element.name}
            currentFilterValues={currentFilterValues}
            setCurrentFilterValues={setCurrentFilterValues}
          />
        );
      } else if (element.fieldType === 'date') {
        obj.children = (
          <DateRangeFilter
            range={ChoicesOrRange[element.name]}
            field={element.name}
            currentFilterValues={currentFilterValues}
            setCurrentFilterValues={setCurrentFilterValues}
          />
        );
      }
      items.push(obj);
    });
    return items;
  };

  return (
    <Drawer
      open={filterOpen}
      placement="left"
      onClose={closeFilter}
      styles={{
        body: {
          padding: 0,
        },
      }}
      title={i18n.t('buttons:filter')}
    >
      <FilterButtonsContainer>
        <ResetFilterButton danger onClick={resetFilters}>
          {i18n.t('buttons:reset_filters')}
        </ResetFilterButton>
        <ApplyFilterButton type="primary" onClick={applyFilters}>
          {i18n.t('buttons:apply')}
        </ApplyFilterButton>
      </FilterButtonsContainer>
      <Collapse items={filterItems()} bordered={false} />
    </Drawer>
  );
}
