import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';
import { Brand, userType } from './AdminTypes';

export type parseBrandsResponse = {
  value: number;
  label: string;
  id: number;
  name: string;
  country?: string;
  address: string;
  city: string;
  country_id: number;
  currency_id: number;
  website: string;
  logo_image_url: string;
};

/**
 * Get all brands based on the type, as admin.
 */
export async function getBrands(
  type: userType,
  createUserModal: boolean = false
): Promise<Brand[] | parseBrandsResponse[]> {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/superuser/brand/${type}`,
    });

    if (!createUserModal) {
      return response;
    } else {
      const parsedData = response.map((brand: parseBrandsResponse) => {
        return {
          value: brand.id,
          label: brand.name,
        };
      }) as Brand[] | parseBrandsResponse[];
      return parsedData;
    }
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
    throw error;
  }
}
