import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { refreshFabricLibraryList } from '../../redux/list';
import { api } from '../api/api';
import { LibPaths, NavigateProp } from './LibraryTypes';

/**
 * Edit Fabric Library name & description.
 * After a fabric library is successfully edited, the fabric library
 * list stored in redux is refreshed.
 */
export async function editLibrary(
  id: string,
  description: string | null,
  name: string,
  navigate: NavigateProp,
  paths: LibPaths
): Promise<void> {
  const data = { description, name };

  try {
    await api({
      method: 'PUT',
      url: `v1/library/fabric/${id}`,
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    refreshFabricLibraryList();
    navigate(paths.FABRICS, { state: { data } });
    message.success(i18n.t('long_messages:message_library_updated_success'));
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
  }
}
