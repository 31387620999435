import { CloseOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { Color } from '../../elements/base/Color';
import { HelpIcon } from '../../elements/icons/StyledIcons';
import i18n from '../../plugins/i18n';
import { parseFabricTitles } from '../../utils/fabrics/parseFabricFields';
import { Modal } from '../Modal';
import { FabricFormSubmitProps } from '../fabric/FabricTypes';
import { PopulateFabricFieldsModalProps } from './ModalTypes';

export function PopulateFabricFieldsModal({
  isVisible,
  onCancel,
  formValues,
  handleSubmit,
  populatedData,
  measurementUnit,
}: PopulateFabricFieldsModalProps) {
  const convertToRecord = (
    data: Array<{ field: string; original: unknown; updated: unknown }> | null
  ): Record<string, unknown> => {
    if (!data) return {};
    return data.reduce(
      (acc, { field, updated }) => {
        acc[field] = updated;
        return acc;
      },
      {} as Record<string, unknown>
    );
  };
  // Initialize state
  const [localPopulatedData, setLocalPopulatedData] = useState<
    Record<string, unknown>
  >(convertToRecord(populatedData));

  const [originalPopulatedData, setOriginalPopulatedData] = useState<
    Record<string, unknown>
  >(convertToRecord(populatedData));

  const [disabledFields, setDisabledFields] = useState<Record<string, boolean>>(
    {}
  );

  // Update localPopulatedData when populatedData changes
  useEffect(() => {
    const newData = convertToRecord(populatedData);
    setLocalPopulatedData(newData);
    setOriginalPopulatedData(newData);
    setDisabledFields({});
  }, [populatedData]);

  // Handle input field change
  const handleInputChange = (field: string, value: number) => {
    setLocalPopulatedData((prev) => ({
      ...prev,
      [field]: Number(value),
    }));
  };

  // Handle input field removal
  const handleInputRemove = (field: string) => {
    setLocalPopulatedData((prev) => ({
      ...prev,
      [field]: '',
    }));
    setDisabledFields((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  // Handle form submission
  const handleModalOk = async () => {
    // Update formValues with the modified data
    const updatedFormValues = {
      ...formValues,
      ...localPopulatedData,
    };
    // Submit the updated values
    handleSubmit(updatedFormValues as FabricFormSubmitProps);
  };

  // Handle resetting form values
  const handleModalReset = () => {
    setDisabledFields({});
    setLocalPopulatedData(originalPopulatedData);
  };

  // Handle declining all inputs
  const handleDeclineAll = () => {
    // Set all fields to empty and disable them
    const emptyData = Object.keys(localPopulatedData).reduce(
      (acc, field) => ({ ...acc, [field]: '' }),
      {}
    );
    setLocalPopulatedData(emptyData);

    // Disable all fields
    setDisabledFields(
      Object.keys(localPopulatedData).reduce(
        (acc, field) => ({ ...acc, [field]: true }),
        {}
      )
    );
  };

  return (
    <Modal
      style={{ marginTop: '100px' }}
      title={i18n.t('headers:fabric_autofill')}
      open={isVisible}
      onCancel={() => onCancel(false)}
      footer={[
        <React.Fragment key="footer">
          <Button onClick={handleModalReset}>{i18n.t('buttons:reset')}</Button>
          <Button danger onClick={handleDeclineAll}>
            {i18n.t('buttons:decline_all')}
          </Button>
          <Button type="primary" onClick={handleModalOk}>
            {i18n.t('buttons:ok')}
          </Button>
        </React.Fragment>,
      ]}
    >
      {i18n.t('long_messages:message_fabric_autofill')}
      <HelpIcon
        title={i18n.t('long_messages:message_fabric_autofill_explaination')}
        margin="0 3px 0 3px"
      />
      <div style={{ marginTop: '20px' }}>
        {Object.keys(localPopulatedData).map((field) => (
          <div key={field} style={{ marginBottom: '10px' }}>
            <label>{parseFabricTitles(field, measurementUnit)}:</label>
            <Input
              value={(localPopulatedData[field] as string) || ''}
              disabled={disabledFields[field]}
              onChange={(e) => handleInputChange(field, Number(e.target.value))}
              style={{ width: '150px', marginLeft: '10px' }}
            />
            <CloseOutlined
              type="default"
              onClick={() => handleInputRemove(field)}
              style={{ marginLeft: '10px', color: Color.Red }}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
}
