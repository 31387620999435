import { Select } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { SharedDesignerBrandsObject } from '../../../utils/backendRequests/collection/CollectionTypes';
import { getSharedDesignerBrands } from '../../../utils/backendRequests/collection/getSharedDesignerBrands';
import { shareCollection } from '../../../utils/backendRequests/collection/shareCollection';
import { Modal } from '../../Modal';
import {
  KeyValueDesignerBrandObject,
  ShareCollectionProps,
} from './CollectionListTypes';
import { SharedDesignerBrands } from './SharedDesignerBrands';

export function ShareCollectionModalWithLink({
  collectionId,
  approvedDesignerBrands,
  getCollectionsList,
}: ShareCollectionProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [sharedDesignerBrands, setSharedDesignerBrands] = useState<
    KeyValueDesignerBrandObject[]
  >([]);
  const [selectedSharedDesigners, setSelectedSharedDesigners] = useState<
    KeyValueDesignerBrandObject[]
  >([]);
  const [unsharedSharedDesigners, setUnsharedSharedDesigners] = useState<
    KeyValueDesignerBrandObject[]
  >([]);

  useEffect(() => {
    async function _getSharedDesignerBrands() {
      // Gets the list of designer brands that the collection is shared with
      const res: SharedDesignerBrandsObject =
        await getSharedDesignerBrands(collectionId);
      if (res) {
        const arraySharedDesignerBrands: KeyValueDesignerBrandObject[] =
          Object.entries(res).map(([key, value]) => {
            return { key, value };
          });
        setSharedDesignerBrands(arraySharedDesignerBrands);
      }
    }
    if (isModalOpen) _getSharedDesignerBrands(); // Only make API call when the modal is opened
  }, [collectionId, isModalOpen]);

  useEffect(() => {
    // Converts list of designer brands (Object) to designer brand ids (number) for easy filtering
    const sharedDesignerBrandsIds = sharedDesignerBrands.map(
      (designerBrand: KeyValueDesignerBrandObject) => designerBrand.key
    );
    // This updates the list of designer brands who have not been shared with previously
    // Users will be unable to select a designer brands they have already shared the collection with
    setUnsharedSharedDesigners(
      approvedDesignerBrands.filter(
        (designerBrand) => !sharedDesignerBrandsIds.includes(designerBrand.key)
      )
    );
  }, [approvedDesignerBrands, sharedDesignerBrands]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedSharedDesigners([]);
  };

  const handleChange = (
    value: KeyValueDesignerBrandObject[],
    option: KeyValueDesignerBrandObject[]
  ) => {
    // Updates the list of designer brands that the user has selected
    setSelectedSharedDesigners(option);
  };

  const handleOk = () => {
    // Merge selected and previously shared with designer brands
    const updatedSharedDesignerBrands = sharedDesignerBrands.concat(
      selectedSharedDesigners
    );
    // Convert designer brands objects to their Ids for backend API call
    const selectedSharedDesignerBradsIds = updatedSharedDesignerBrands.map(
      (designerBrand: KeyValueDesignerBrandObject) => designerBrand.key
    );
    shareCollection(collectionId, selectedSharedDesignerBradsIds);
    // Updates list of shared designer brands after API call is completed
    getCollectionsList();
    // Clears the list of selected designer brands to prevent resubmission of same designer brands
    setSelectedSharedDesigners([]);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <a onClick={showModal} role="button">
        {i18n.t('buttons:share')}
      </a>
      <Modal
        title={i18n.t('headers:share_with_designer_brands')}
        open={isModalOpen}
        okText={i18n.t('buttons:share')}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder={i18n.t('headers:add_more_designer_brands')}
          // @ts-expect-error because Antd restrictions
          onChange={handleChange}
          options={unsharedSharedDesigners}
          value={selectedSharedDesigners}
        />
        <SharedDesignerBrands
          sharedDesignerBrands={sharedDesignerBrands}
          collectionId={collectionId}
          setSharedDesignerBrands={setSharedDesignerBrands}
        />
      </Modal>
    </>
  );
}
