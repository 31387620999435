import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Send a user upload to the bin based on its id.
 */
export async function deleteToBinUserUpload(
  userUploadId: string
): Promise<boolean> {
  try {
    await api({
      method: 'PUT',
      url: `v1/user_upload/${userUploadId}/delete_to_bin`,
    });
    message.success(i18n.t('long_messages:message_user_upload_bin_success'));
    return true;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response?.data as { msg?: string })?.msg);
    }
    return false;
  }
}
