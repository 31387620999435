import { Button, Checkbox, Col, Row } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import React from 'react';
import { SelectableFieldContainer } from '../../elements/modals/StyledModal';
import { formFieldsProp } from '../../utils/fabrics/FabricsTypes';
import { parseFabricTitles } from '../../utils/fabrics/parseFabricFields';
import {
  getSelectedLabelsFromLocalStorage,
  setSelectedLabelsInStorage,
} from '../../utils/fabrics/selectedFabricTableColumnsLabels';
import { RootAuth } from '../../utils/types/Types';
import { Modal } from '../Modal';
import {
  selectableColumns as columns,
  getSelectedColumns,
  selectableDefaultColumnsLabels,
} from '../table/fabricTable/ColumnsDesktop';
import { RenderColumnBatch, SelectFabricColumnsProps } from './ModalTypes';

// Define fabric label column constants
const DEFAULT_EXTRA_COLUMNS = 2;
const COLUMNS_PER_ROW = 12;

export function SelectFabricColumns({
  modal,
  setModal,
  setSelectedCol,
  pageType,
  selectableColumnNames,
  includeSimilarity = false,
}: SelectFabricColumnsProps) {
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const [selectedLabels, setSelectedLabels] = useState(
    getSelectedLabelsFromLocalStorage(false)
  );
  const [selectAllMode, setSelectAllMode] = useState(true); // Initial state for the button
  const [filteredColumns, setFilteredColumns] = useState<formFieldsProp[] | []>(
    []
  ); // Initial state for the button

  useEffect(() => {
    const selectedColumnNames = columns.filter((column) =>
      selectableColumnNames.includes(column.name)
    );
    setFilteredColumns(selectedColumnNames);
  }, [selectableColumnNames]);

  const handleUpdateColumn = (selectColumn: string) => {
    const updatedColumns = selectedLabels.includes(selectColumn)
      ? selectedLabels.filter(
          (selectedColumn) => selectedColumn !== selectColumn
        ) // remove column from selected columns
      : [...selectedLabels, selectColumn]; // add column to selected columns

    setSelectedLabels(updatedColumns);
  };

  const handleDefault = () => {
    setSelectedLabels(selectableDefaultColumnsLabels);
  };

  const handleCancel = () => {
    const storedLabels = getSelectedLabelsFromLocalStorage(false);
    setSelectedLabels(storedLabels);
    setModal({ visible: false });
  };

  const handleOk = () => {
    setSelectedLabelsInStorage(selectedLabels, false);
    const columns = getSelectedColumns(
      pageType,
      measurementUnit,
      role,
      selectableColumnNames,
      account,
      includeSimilarity
    );
    setSelectedCol(columns);
    setModal({ visible: false });
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      setSelectedLabels(filteredColumns.map((col) => col.name));
    } else {
      setSelectedLabels([]);
    }
  };

  // Deselect all only available if all possible columns are selected
  useEffect(() => {
    setSelectAllMode(selectedLabels.length !== filteredColumns.length);
  }, [selectedLabels]);

  // Split labels into columns for modal display
  // Determine the number of columns with a minimum of 2
  const numColumns = Math.max(
    DEFAULT_EXTRA_COLUMNS,
    Math.min(
      4,
      Math.ceil(
        (filteredColumns.length + DEFAULT_EXTRA_COLUMNS) / COLUMNS_PER_ROW
      )
    ) // Adding 2 to total to account for the 2 extra in the first column
  );

  // Calculate the size of each column batch, accounting for the extra 2 in the first column
  const columnBatchSize = Math.ceil(
    (filteredColumns.length + DEFAULT_EXTRA_COLUMNS) / numColumns
  );

  // Adjust the first column batch size to be 2 less than the calculated batch size
  const firstColumnBatchSize = columnBatchSize - DEFAULT_EXTRA_COLUMNS;

  // Create column batches
  const columnBatches = Array.from({ length: numColumns }, (_, i) => {
    const startIdx =
      i === 0 ? 0 : firstColumnBatchSize + (i - 1) * columnBatchSize;
    const endIdx = i === 0 ? firstColumnBatchSize : startIdx + columnBatchSize;
    return filteredColumns
      .slice(startIdx, endIdx)
      .filter((col) => col.name !== 'similarity_score');
  });

  const renderColumn = (
    batch: RenderColumnBatch[],
    selectedColumns: string[],
    handleUpdateColumn: (selectColumn: string) => void,
    index: number,
    renderDefaults: boolean
  ) => {
    const DefaultSelectedColumns = ['name', 'image_url_front'];
    return (
      <Col key={index} span={24 / numColumns}>
        {/* Default columns only appear once in first column*/}
        {renderDefaults &&
          DefaultSelectedColumns.map((col) => (
            <SelectableFieldContainer key={col}>
              <Checkbox checked disabled />
              <span style={{ marginLeft: '8px' }}>
                {parseFabricTitles(col, measurementUnit)}
              </span>
            </SelectableFieldContainer>
          ))}
        {/* Render the rest of the batch */}
        {batch.map((col) => (
          <SelectableFieldContainer key={col.name}>
            <Checkbox
              checked={selectedColumns.includes(col.name)}
              onChange={() => handleUpdateColumn(col.name)}
            />
            <span style={{ marginLeft: '8px' }}>
              {parseFabricTitles(col.name, measurementUnit)}
            </span>
          </SelectableFieldContainer>
        ))}
      </Col>
    );
  };

  const modalWidth = `${Math.min(800, numColumns * 200)}px`; // Adjust maximum width and column width as needed

  return (
    <Modal
      title={i18n.t('headers:select_fabric_columns')}
      width={modalWidth}
      open={modal.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <React.Fragment key="footer">
          <Button onClick={handleToggleAll}>
            {selectAllMode
              ? i18n.t('buttons:select_all')
              : i18n.t('buttons:deselect_all')}
          </Button>
          <Button onClick={handleDefault}>{i18n.t('buttons:default')}</Button>
          <Button danger onClick={handleCancel}>
            {i18n.t('buttons:cancel')}
          </Button>
          <Button type="primary" onClick={handleOk}>
            {i18n.t('buttons:ok')}
          </Button>
        </React.Fragment>,
      ]}
    >
      <Row gutter={[12, 12]} wrap={false}>
        {columnBatches.map((batch, index) =>
          renderColumn(
            batch,
            selectedLabels,
            handleUpdateColumn,
            index,
            index === 0
          )
        )}
      </Row>
    </Modal>
  );
}
