import { Button, Modal } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';

import { Color } from '../../../elements/base/Color';
import { binProject } from '../../../utils/backendRequests/project/binProject';
import { BinProjectButtonProps } from '../TableTypes';

export function BinProjectButton({ projectId }: BinProjectButtonProps) {
  const [modalVisible, setModalVisible] = useState(false);

  const handleConfirm = async () => {
    // Bin Project
    await binProject(projectId);
    setModalVisible(false); // Close the modal after deletion
  };

  return (
    <>
      <Button danger onClick={() => setModalVisible(true)}>
        {i18n.t('buttons:delete')}
      </Button>
      <Modal
        title={i18n.t('headers:confirm_deletion')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_project_deletion')}</p>
      </Modal>
    </>
  );
}
