import { EditFabricPage } from '../pages/fabric/EditFabricPage';
import { FabricDetailsPage } from '../pages/fabric/FabricDetailsPage';
import { CompareFabricsPage } from '../pages/search/Compare';
import { SearchMobileLaunchPage } from '../pages/search/MobileLaunch';
import { SearchResultsPage } from '../pages/search/Results';
import { SearchResultsMobilePage } from '../pages/search/ResultsMobile';
import { HistoryListPage } from '../pages/search/historyTab/SearchHistoryListPage';
import { RouteProps } from './RouteTypes';

// TODO: Children of children does not work due to how the routes are being read at the moment
export const routesSearch: RouteProps[] = [
  {
    path: '/search',
    title: 'Search',
    page: SearchMobileLaunchPage,
    children: [
      {
        /**
         * This page is to display search results after a successful fabric text search
         * search on desktop.
         */
        path: '/text/:textId',
        title: 'Text Results',
        page: SearchResultsPage,
      },
      {
        /**
         * This page is to display search results after a successful fabric image search
         * search on desktop.
         */
        path: '/image/:imageId',
        title: 'Image Results',
        page: SearchResultsPage,
      },
      {
        /**
         * This page is to display the fabric details obtained from a fabric image search
         * to facilitate the redirect of the webpage
         */
        path: '/:fabricId',
        title: 'Fabric Details',
        page: FabricDetailsPage,
      },
      {
        /**
         * This page is to edit the fabric details obtained from a fabric image search
         * to facilitate the redirect of the webpage
         */
        path: '/:fabricId/edit',
        title: 'Fabric Id',
        page: EditFabricPage,
      },
      {
        path: '/results-mobile',
        title: 'Results Mobile',
        page: SearchResultsMobilePage,
      },
    ],
  },
  /**
   * This page displays fabrics selected to be compared in the searches (text || image)
   * Cannot make it under search/compare because the logic in Breadcrumbs will fetch 'compare' as a fabric name
   */
  {
    path: '/compare',
    title: 'Compare Fabrics',
    page: CompareFabricsPage,
  },
  {
    path: '/search-history',
    title: 'History',
    key: 'history_page',
    page: HistoryListPage,
  },
];
