import { Button, Checkbox, Col, Input, Pagination, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import {
  CollectionFieldsContainer,
  CollectionModalContainer,
} from '../../../elements/modals/StyledModal';
import { copyLibraryFabricsToCollection } from '../../../utils/backendRequests/collection/copyLibraryFabricsToCollection';
import { createCollection } from '../../../utils/backendRequests/collection/createCollection';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { RootAuth, Setter } from '../../../utils/types/Types';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';

type CreateCollectionModalProps = {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  setParentModal?: Setter<{ visible: boolean }>;
  fabricIds?: string[];
  libraryId?: string | null;
  getData: () => void;
};

export const defaultFieldsToShowOptions = [
  'name',
  'fabric_type',
  'image_url_front',
  'updated_at',
  'created_at',
  'ml_pattern_tags',
  'ml_description',
  'construction',
];

export const fieldToShowOptions = [
  'country_of_origin',
  'original_supplier',
  'weight_grams_per_sqm',
  'weight_grams_per_m',
  'weight_grams_per_piece',
  'length_cm_per_piece',
  'width_cm',
  'compositions',
  'image_url_back',
  'image_url_macro',
  'image_url_header',
  'description',
  'notes',
  'colours',
  'colour_fastness',
  'wash_care',
  'piling',
  'gauge_inch',
  'yarn_count',
  'stretch',
  'functional_finish',
  'season',
  'creation_date',
  'usage_category',
  'look',
  'special_yarn',
  'sustainability',
  'hand_feel',
  'repeat',
  'lace_structure',
  'pattern_design',
  'aesthetic_finish',
  'edge_finish',
  'price_per_sqm',
  'price_per_m',
  'price_per_kg',
  'price_per_piece',
  'lead_time_days',
  'moq_m',
  'mcq_m',
  'moq_sqm',
  'mcq_sqm',
  'moq_piece',
  'mcq_piece',
  'moq_kg',
  'mcq_kg',
  'availability',
  'stock_m',
  'stock_sqm',
  'stock_piece',
  'stock_kg',
  'attachments',
  'certifications',
  'name_from_original_supplier',
];

export function CreateCollectionModal({
  modal,
  setModal,
  setParentModal = () => {},
  fabricIds = [],
  libraryId = null,
  getData,
}: CreateCollectionModalProps) {
  const [collectionName, setCollectionName] = useState<string>('');
  const [description, setDescription] = useState<string | null>(null);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectAllMode, setSelectAllMode] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1); // For pagination

  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  const pageSize = 24; // Number of checkboxes per page

  useEffect(() => {
    if (!modal.visible) {
      setCollectionName('');
      setDescription(null);
      setSelectedFields([]);
      setCurrentPage(1); // Reset pagination when modal is closed
    }
  }, [modal.visible]);

  useEffect(() => {
    setSelectAllMode(
      selectedFields.length < combinedFieldOptions.length &&
        selectedFields.length > defaultFieldsToShowOptions.length
    );
  }, [selectedFields]);

  const handleFieldChange = (field: string, checked: boolean) => {
    if (checked) {
      setSelectedFields((prev) => [...prev, field]);
    } else {
      setSelectedFields((prev) => prev.filter((item) => item !== field));
    }
  };

  const handleSuccess = () => {
    setCollectionName('');
    setDescription(null);
    setModal({ visible: false });
    getData();
  };

  const handleCancel = () => {
    setModal({ visible: false });
    setParentModal({ visible: true });
  };

  const handleSelectAll = () => {
    setSelectedFields((prev) => [
      ...new Set([
        ...prev,
        ...combinedFieldOptions.filter(
          (field) => !defaultFieldsToShowOptions.includes(field)
        ),
      ]),
    ]);
  };

  const handleDeselectAll = () => {
    setSelectedFields((prev) =>
      prev.filter((field) => defaultFieldsToShowOptions.includes(field))
    );
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  const handleSubmit = async () => {
    /**
     * Either:
     * - Creates a new collection with all the fabrics given to it,
     * - Creates a new empty collection and then adds all the fabrics in a fabric library to it.
     */
    // Only send non default fields to show to backend
    const selectedCustomFields = selectedFields.filter(
      (field) => !defaultFieldsToShowOptions.includes(field)
    );

    const isNameValid = stringValid(
      collectionName,
      50,
      i18n.t('common:name'),
      false
    );

    const isDescriptionValid = stringValid(
      description,
      500,
      i18n.t('column_titles:description'),
      true
    );
    if (isNameValid && isDescriptionValid) {
      const descriptionValue = description === '' ? null : description;
      if (libraryId === null) {
        await createCollection(
          collectionName,
          descriptionValue,
          fabricIds,
          selectedCustomFields
        ).then(() => {
          handleSuccess();
        });
      } else {
        await createCollection(
          collectionName,
          descriptionValue,
          fabricIds,
          selectedCustomFields
        ).then((collection) => {
          copyLibraryFabricsToCollection(collection.id, libraryId).then(() => {
            handleSuccess();
          });
        });
      }
    }
  };

  // Paginate the combinedFieldOptions
  const combinedFieldOptions = [
    ...defaultFieldsToShowOptions,
    ...fieldToShowOptions,
  ];

  const paginatedOptions = combinedFieldOptions.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const renderCheckboxes = (options: string[], disabled = false) => (
    <Row gutter={[8, 4]}>
      {options.map((field) => (
        <Col span={8} key={field}>
          <CollectionModalContainer>
            <Checkbox
              checked={
                selectedFields.includes(field) ||
                defaultFieldsToShowOptions.includes(field)
              }
              disabled={defaultFieldsToShowOptions.includes(field) || disabled}
              onChange={(e) => handleFieldChange(field, e.target.checked)}
              style={{ marginRight: '4px' }}
            />
            <span>{parseFabricTitles(field, measurementUnit)}</span>
          </CollectionModalContainer>
        </Col>
      ))}
    </Row>
  );

  return (
    <Modal
      title={i18n.t('headers:new_collection')}
      open={modal.visible}
      width={700}
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <>
          <Button onClick={handleToggleAll}>
            {selectAllMode
              ? i18n.t('buttons:deselect_all')
              : i18n.t('buttons:select_all')}
          </Button>
          <Button danger onClick={handleCancel}>
            {i18n.t('buttons:cancel')}
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            {i18n.t('buttons:ok')}
          </Button>
        </>
      }
    >
      {`${i18n.t('common:name')}:`}
      <Input
        value={collectionName}
        onChange={(e) => setCollectionName(e.target.value)}
      />
      {`${i18n.t('column_titles:description')}:`}
      <Input
        showCount
        value={description !== null ? description : ''}
        style={{ marginTop: '10px', marginBottom: '10px' }}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Space style={{ paddingBottom: '10px' }}>
        {i18n.t('column_titles:fabric_fields_to_show')}
        <HelpIcon
          title={i18n.t('long_messages:message_fabric_show_coumns_message')}
          margin="0 0px 0 -3px"
        />
      </Space>
      {renderCheckboxes(paginatedOptions)}
      <CollectionFieldsContainer>
        <Pagination
          current={currentPage}
          total={combinedFieldOptions.length}
          pageSize={pageSize}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: '20px', textAlign: 'center' }}
          showSizeChanger={false}
        />
      </CollectionFieldsContainer>
    </Modal>
  );
}
