import { message } from 'antd';
import { isAxiosError } from 'axios';
import { api } from '../api/api';

/**
 * Get SupplierBrandId from fabric id, as admin.
 */
export async function getSupplierBrandIdFromFabricId(
  fabricId: string
): Promise<number | null> {
  try {
    const response = await api({
      method: 'GET',
      url: `v1/superuser/user/supplier_brand_id/fabric/${fabricId}`,
    });
    return Number(response);
  } catch (error) {
    if (isAxiosError(error)) {
      message.error(error.response?.data.msg);
    }
    return Promise.reject(error);
  }
}
