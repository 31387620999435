import { message } from 'antd';
import { isAxiosError } from 'axios';

import { api } from '../api/api';
import { SupplierType } from './supplierTypes';
/**
 * Makes an API call for suppliers connected to the current
 * designer user.
 */
export async function getSuppliers(): Promise<SupplierType[]> {
  let results = [];
  try {
    const response = await api({
      method: 'GET',
      url: 'v1/brand/designer/approved_supplier_brand',
    });
    results = response;
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
  }
  return results;
}
