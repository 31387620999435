import { Button, Checkbox, Col, Input, Pagination, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import {
  CollectionFieldsContainer,
  CollectionModalContainer,
} from '../../../elements/modals/StyledModal';
import { updateCollectionDetails } from '../../../utils/backendRequests/collection/updateCollectionDetails';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { RootAuth } from '../../../utils/types/Types';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { EditCollectionModalWithLinkProps } from '../../table/collectionList/CollectionListTypes';
import {
  defaultFieldsToShowOptions,
  fieldToShowOptions,
} from './CreateCollectionModal';

export function EditCollectionModalWithLink({
  record,
  getCollectionsList,
}: EditCollectionModalWithLinkProps) {
  const [modal, setModal] = useState<{ visible: boolean }>({ visible: false });
  const { name, id, description, fields_to_show } = record;
  const [newName, setNewName] = useState<string>(name);
  const [newDesc, setNewDesc] = useState<string | null>(description);
  const [currentPage, setCurrentPage] = useState<number>(1); // For pagination
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectAllMode, setSelectAllMode] = useState<boolean>(false);
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  const pageSize = 24; // Number of checkboxes per page

  const handleCancel = () => {
    setModal({ visible: false });
  };

  // Reset newName when name changes
  useEffect(() => {
    setNewName(name);
    setNewDesc(description);
    setSelectedFields(fields_to_show);
  }, [name, description, fields_to_show]);

  useEffect(() => {
    // Determine whether to show "Select All" or "Deselect All" based on current selected fields
    setSelectAllMode(
      selectedFields.length < combinedFieldOptions.length &&
        selectedFields.length > defaultFieldsToShowOptions.length
    );
  }, [selectedFields]);

  const handleSelectAll = () => {
    setSelectedFields((prev) => [
      ...new Set([
        ...prev,
        ...combinedFieldOptions.filter(
          (field) => !defaultFieldsToShowOptions.includes(field)
        ),
      ]),
    ]);
  };

  const handleDeselectAll = () => {
    setSelectedFields((prev) =>
      prev.filter((field) => defaultFieldsToShowOptions.includes(field))
    );
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  const handleOK = async () => {
    const isNameValid = stringValid(newName, 50, i18n.t('common:name'), false);
    const isDescriptionValid = stringValid(
      newDesc,
      500,
      i18n.t('column_titles:description'),
      true
    );
    // Only send non default fields to show to backend
    const selectedCustomFields = selectedFields.filter(
      (field) => !defaultFieldsToShowOptions.includes(field)
    );

    if (isNameValid && isDescriptionValid) {
      const descriptionValue = newDesc === '' ? null : newDesc;
      const response = await updateCollectionDetails(
        id,
        newName,
        descriptionValue,
        selectedCustomFields
      );
      if (response) {
        setModal({ visible: false });
        getCollectionsList();
      } else {
        setModal({ visible: true });
      }
    }
  };

  const showModal = () => {
    setModal({ visible: true });
    setNewName(name);
  };

  const handleFieldChange = (field: string, checked: boolean) => {
    if (checked) {
      setSelectedFields((prev) => [...prev, field]);
    } else {
      setSelectedFields((prev) => prev.filter((item) => item !== field));
    }
  };

  // Paginate the combinedFieldOptions
  const combinedFieldOptions = [
    ...defaultFieldsToShowOptions,
    ...fieldToShowOptions,
  ];

  const paginatedOptions = combinedFieldOptions.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const renderCheckboxes = (options: string[], disabled = false) => (
    <Row gutter={[8, 4]}>
      {options.map((field) => (
        <Col span={8} key={field}>
          <CollectionModalContainer>
            <Checkbox
              checked={
                selectedFields.includes(field) ||
                defaultFieldsToShowOptions.includes(field)
              }
              disabled={defaultFieldsToShowOptions.includes(field) || disabled}
              onChange={(e) => handleFieldChange(field, e.target.checked)}
              style={{ marginRight: '4px' }}
            />
            <span>{parseFabricTitles(field, measurementUnit)}</span>
          </CollectionModalContainer>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <a role="button" onClick={showModal}>
        {i18n.t('buttons:edit')}
      </a>
      <Modal
        title={i18n.t('headers:edit_collection')}
        open={modal.visible}
        width={700}
        maskClosable={false}
        onCancel={handleCancel}
        footer={
          <>
            <Button onClick={handleToggleAll}>
              {selectAllMode
                ? i18n.t('buttons:deselect_all')
                : i18n.t('buttons:select_all')}
            </Button>
            <Button danger onClick={handleCancel}>
              {i18n.t('buttons:cancel')}
            </Button>
            <Button type="primary" onClick={handleOK}>
              {i18n.t('buttons:ok')}
            </Button>
          </>
        }
      >
        {`${i18n.t('common:name')}:`}
        <Input
          allowClear
          onChange={(evt) => setNewName(evt.target.value)}
          value={newName}
          style={{ marginBottom: '10px' }}
        />
        {`${i18n.t('column_titles:description')}:`}
        <Input
          showCount
          value={newDesc !== null ? newDesc : ''}
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onChange={(evt) => setNewDesc(evt.target.value)}
        />
        <Space style={{ paddingBottom: '10px' }}>
          {i18n.t('column_titles:fabric_fields_to_show')}
          <HelpIcon
            title={i18n.t('long_messages:message_fabric_show_coumns_message')}
            margin="0 0px 0 -3px"
          />
        </Space>
        {renderCheckboxes(paginatedOptions)}
        <CollectionFieldsContainer>
          <Pagination
            current={currentPage}
            total={combinedFieldOptions.length}
            pageSize={pageSize}
            onChange={(page) => setCurrentPage(page)}
            style={{ marginTop: '20px', textAlign: 'center' }}
            showSizeChanger={false}
          />
        </CollectionFieldsContainer>
      </Modal>
    </>
  );
}
