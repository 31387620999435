import { Col, Row, Space } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { TextB1 } from '../typography/TextB1';
import { TextB2 } from '../typography/TextB2';

// Styled component for the horizontal Space with default props
export const StyledHorizontalSpace = styled(Space).attrs({
  align: 'start',
  direction: 'horizontal',
  size: 'large',
})`
  display: flex;
  justify-content: space-evenly;
`;

// Styled component for the vertical Space with default props
export const StyledVerticalSpace = styled(Space).attrs({
  direction: 'vertical',
  size: 'large',
})`
  width: 200px;
  margin: auto;
  display: block;
`;

export const FabricCardText = styled(TextB2)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

// Styled Fabric Technical Tab
const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

// Styled Col wrapper component
const StyledCol = styled(Col)`
  span: 24;
`;

// Define the props type, including children
interface TechnicalFieldsWrapperProps {
  children: ReactNode;
}

// Wrapper component that accepts children
export const TechnicalFieldsWrapper = ({
  children,
}: TechnicalFieldsWrapperProps) => {
  return (
    <StyledRow gutter={[16, 16]}>
      <StyledCol>{children}</StyledCol>
    </StyledRow>
  );
};

export const StyledTechicalField = styled(TextB1)`
  white-space: pre-wrap;
  margin-right: 10px;
`;

// Show Fabric Card
export const FabricCardContainer = styled.div<{ $isMobile: boolean }>`
  cursor: pointer;
  width: ${({ $isMobile }) => ($isMobile ? '70vw' : '12vw')};
  margin: ${({ $isMobile }) => ($isMobile ? '0 5px' : '0 10px')};
`;

export const FabricCardTextContainer = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;
