import { Input } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { copyCollectionFabricsToProject } from '../../../utils/backendRequests/project/copyCollectionFabricsToProject';
import { copyProjectToExistingProject } from '../../../utils/backendRequests/project/copyProjectToExistingProject';
import { createProject } from '../../../utils/backendRequests/project/createProject';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { CreateProjectModalProps } from './ProjectModalTypes';

export function CreateProjectModal({
  modal,
  setModal,
  setParentModal = () => {},
  fabricIds = [],
  collectionId = null,
  srcProjectId = null,
}: CreateProjectModalProps) {
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState<string | null>(null);

  useEffect(() => {
    if (!modal.visible) {
      setProjectName('');
      setDescription(null);
    }
  }, [modal.visible]);

  const handleSuccess = () => {
    setProjectName('');
    setDescription(null);
    setModal({ visible: false });
  };

  const handleCancel = () => {
    setModal({ visible: false });
    setParentModal({ visible: true });
  };

  const handleSubmit = async () => {
    /**
     * Either:
     * - Creates a new empty project and then adds all the fabrics in a collection to it,
     * - Creates a new empty project and then adds all the fabrics in a project to it,
     * - Creates a new project with all given fabrics.
     */
    const isNameValid = stringValid(
      projectName,
      50,
      i18n.t('common:name'),
      false
    );

    const isDescriptionValid = stringValid(
      description,
      500,
      i18n.t('column_titles:description'),
      true
    );
    if (isNameValid && isDescriptionValid) {
      const descriptionValue = description === '' ? null : description;
      if (collectionId !== null) {
        await createProject(projectName, descriptionValue, [])
          .then(() => {
            copyCollectionFabricsToProject(projectName, collectionId);
          })
          .then(() => {
            handleSuccess();
          });
      } else if (srcProjectId !== null) {
        await createProject(projectName, description, [])
          .then((res) => {
            copyProjectToExistingProject(srcProjectId, res.id);
          })
          .then(() => {
            handleSuccess();
          });
      } else {
        await createProject(projectName, descriptionValue, fabricIds).then(
          () => {
            handleSuccess();
          }
        );
      }
    }
  };

  return (
    <Modal
      title={i18n.t('headers:new_project')}
      open={modal.visible}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      {`${i18n.t('common:name')}:`}
      <Input
        value={projectName}
        onChange={(e) => {
          setProjectName(e.target.value);
        }}
      />
      {`${i18n.t('column_titles:description')}:`}
      <Input
        showCount
        value={description !== null ? description : ''}
        style={{ marginTop: '10px' }}
        onChange={(evt) => setDescription(evt.target.value)}
      />
    </Modal>
  );
}
