import { message } from 'antd';
import { isAxiosError } from 'axios';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Remove fabrics from a Fabric Project via their id
 * Save result to state.
 */
export async function deleteFabricsFromProjects(
  projectId: string,
  fabricsIdsToRemove: string[]
): Promise<{
  newSelectedRowKeys: number[];
  refreshCount: (n: number) => number;
} | null> {
  const data = { fabric_ids: fabricsIdsToRemove };

  try {
    await api({
      method: 'DELETE',
      url: `v1/project/${projectId}/fabric`,
      body: data,
    });
    message.success(
      i18n.t('long_messages:message_project_removed_fabric_success')
    );
    return { newSelectedRowKeys: [], refreshCount: (n: number) => n + 1 };
  } catch (error) {
    if (isAxiosError(error)) {
      message.error((error.response?.data as { msg?: string })?.msg);
    } else if (error instanceof Error) {
      message.error(error.message);
    }
    return Promise.reject(error);
  }
}
