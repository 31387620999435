import i18n from 'i18next';
import { createHangerRequest } from '../../utils/backendRequests/fabric/createHangerRequest';
import { Modal } from '../Modal';

interface CreateHangerRequestModalProps {
  visible: boolean;
  onCancel: () => void;
  fabricId: string;
}

export function CreateHangerRequestModal({
  visible,
  onCancel,
  fabricId,
}: CreateHangerRequestModalProps) {
  // Request a hanger
  const handleHangerRequest = async () => {
    await createHangerRequest(fabricId);
    onCancel();
  };

  return (
    <Modal
      title={i18n.t('headers:request_hanger')}
      open={visible}
      onCancel={onCancel}
      okText={i18n.t('buttons:yes')}
      onOk={handleHangerRequest}
    >
      {i18n.t('long_messages:request_hanger_modal_text')}
    </Modal>
  );
}
