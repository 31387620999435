import { message } from 'antd';
import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Sends a request for a hanger of the specified fabric via email.
 */
export const createHangerRequest = async (fabricId: string): Promise<void> => {
  try {
    return await api({
      method: 'POST',
      url: `v1/fabric/${fabricId}/request_hanger`,
    }).then(() => {
      message.success(i18n.t('long_messages:request_hanger_success_message'));
    });
  } catch (error) {
    if (error instanceof Error) {
      message.error(error.message);
    }
  }
};
